import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="lh-lg bg-dark text-light text-center py-5">
    <h1>Πρέπει να διέγραψα κατά λάθος αυτή την σελίδα <span className="nobr">(╯°□°）╯︵ ┻━┻</span></h1>
    </div>
  </Layout>
)

export default NotFoundPage
